<template>
  <v-carousel cycle interval="5000" class="carousel--wrapper">
    <v-carousel-item v-for="banner in banners" :key="banner.uuid" class="carousel--promo-slide">
      <router-link :to="`/promo/detail/${banner.uuid}`" class="carousel--promo-link">
        <div class="carousel--promo-image-wrapper">
          <picture>
            <source :srcset="banner.image_url" type="image/webp" />
            <source :srcset="getFallbackImage(banner.image_url)" type="image/jpeg" />
            <img
              :src="getFallbackImage(banner.image_url)"
              class="carousel--promo-image img-responsive"
              :alt="`${banner.title} - Rentfix`"
            />
          </picture>
          <router-link to="/promo" class="see--all" v-show="banner.is_promo">
            <div class="btn btn-primary">Lihat Semua Promo</div>
          </router-link>
        </div>
      </router-link>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: {
    banners: {
      default: [],
      require: true,
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
