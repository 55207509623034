<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <section class="home--promo-container">
      <h2 class="title--text">
        {{ $t('home.promo') }}
      </h2>
      <div class="slider">
        <client-only>
          <slick-promo :banners="banners" v-if="banners && banners.length > 0"></slick-promo>
        </client-only>
      </div>
    </section>
  </div>
</template>

<script>
import SlickPromo from './partials/slick-promo';
export default {
  components: { SlickPromo },
  computed: {
    banners() {
      return this.$store.state.banner.banners;
    },
  },
  methods: {
    visibilityChanged(value) {
      if (value) {
        this.getBanners();
      }
    },
    getBanners() {
      this.$store.dispatch('banner/getBanner');
    },
  },
};
</script>
