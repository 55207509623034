<template>
  <section class="home--about-container">
    <div class="container">
      <div class="title--text mb-4-5">
        {{ $t('home.about.title') }}
      </div>
      <div class="content mb-4-5">
        <div class="content--text" v-html="$t('home.about.message1')"></div>
      </div>
      <div class="see--all">
        <router-link to="/about-us" class="btn">
          {{ $t('home.seeMore') }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {},
  methods: {},
};
</script>
