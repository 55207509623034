<template>
  <section
    class="home--panorama-container"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="container">
      <div class="inner--container">
        <div class="col-12 col-md-6 left--col">
          <h2 class="title--text">
            {{ $t('home.panorama.panoramaTitle') }}
          </h2>
          <div class="desc">
            {{ $t('home.panorama.panoramaSub') }}
          </div>
          <router-link to="/sewa/search/semua/?listing_type=R&pano_required=true" class="btn">
            {{ $t('home.panorama.panoramaBtn') }}
          </router-link>
        </div>
        <div class="col-12 col-md-6 right--col">
          <img v-if="!panoUrl" src="/img/placeholder.png" alt="Placeholder" />
          <iframe
            v-else
            class="home--panorama-iframe"
            :src="panoUrl"
            allowfullscreen="allowfullscreen"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'panorama-home',
  mixins: [HelperMixin],
  data() {
    return {
      panoUrl: null,
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.getPanoList();
      }
    },
    async getPanoList() {
      console.log('oy pano get pano list');
      let { data } = await this.$http.get('/api/v2/home/get_home_pano_list');
      const randomIndex = this.getRandomInt(0, data.data.length);
      this.panoUrl = data.data[randomIndex];
    },
  },
  mounted() {
    console.log('oy pano mounted');
  },
};
</script>
