<template>
  <section
    class="home--partner-container"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="container">
      <div class="d-flex justify-space-between align-center mb-4-5">
        <h2 class="title--text mb-4">
          {{ $t('home.partner.title') }}
        </h2>
        <div class="see--all">
          <router-link class="btn btn-primary btn--all mr-3 my-auto col-auto" :to="seeAllUrl">{{
            $t('home.seeAll')
          }}</router-link>
        </div>
      </div>
      <!--      <div class="switch mb-4">-->
      <!--        <div-->
      <!--          class="btn mr-2"-->
      <!--          :class="type === 'R' ? 'btn-primary' : 'btn-primary&#45;&#45;outline'"-->
      <!--          @click="changeType('R')"-->
      <!--        >-->
      <!--          {{ $t('general.R') }}-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="btn"-->
      <!--          :class="type === 'S' ? 'btn-primary' : 'btn-primary&#45;&#45;outline'"-->
      <!--          @click="changeType('S')"-->
      <!--        >-->
      <!--          {{ $t('general.S') }}-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div-->
      <!--        class="slider"-->
      <!--        v-if="-->
      <!--          (listingPartners && listingPartners.length) || (projectPartners && projectPartners.length)-->
      <!--        "-->
      <!--      >-->
      <!--        <slick-partner-->
      <!--          :listingPartners="listingPartners"-->
      <!--          :projectPartners="projectPartners"-->
      <!--          :partnerType="type"-->
      <!--        />-->
      <!--      </div>-->
      <div class="slider">
        <!--        <slick-partner-->
        <!--          :listingPartners="listingPartners"-->
        <!--          :projectPartners="projectPartners"-->
        <!--          :partnerType="type"-->
        <!--        />-->
        <slick-official-partner :officialPartnerAreas="officialPartnerAreas" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import SlickOfficialPartner from './partials/slick-official-partner';
export default {
  components: { SlickOfficialPartner },
  data: () => ({
    type: 'R',
  }),
  computed: {
    ...mapState({
      // listingPartners: state => state.v2.masters.listingPartner,
      // projectPartners: state => state.v2.masters.projectPartners,
      officialPartnerAreas: (state) => state.v2.officialPartner.areaList,
    }),
    seeAllUrl() {
      let listingTypeUrl = this.type === 'R' ? 'sewa' : 'beli';
      return `/${listingTypeUrl}/search/semua/?is_official_partner=true`;
    },
  },
  methods: {
    changeType(newType) {
      this.type = newType;
      this.getPartners();
    },
    visibilityChanged(value) {
      if (value) {
        this.getPartners();
      }
    },
    async getPartners() {
      // console.log('masuk sini cuy');
      // await Promise.all([
      //   this.$store.dispatch('v2/masters/getListingPartner'),
      //   this.$store.dispatch('v2/masters/getProjectPartners'),
      // ]);
      await this.$store.dispatch('v2/officialPartner/getAreaList', { listingType: this.type });
    },
  },
};
</script>
