<template>
  <default-layout :homepage="false" :breadcrumbs="[]">
    <h1 style="display: none">{{ $t('general.homepage') }}</h1>
    <search />
    <!--    <client-only>-->
    <!--      <promo />-->
    <!--    </client-only>-->
    <listings
      :useBackground="true"
      key="listings-co-working"
      :title="$t('homepage.title.coWorking')"
      :category="'co-working'"
      :seeAllUrl="'/sewa/search/co-working%20space-serviced%20office/?listing_type=R&sort_by=1&property_type_id=%255B8%252C14%255D'"
      :listingType="'R'"
    />
    <!--    <listings-->
    <!--      key="listings-good-deals"-->
    <!--      :title="$t('homepage.title.flashSale')"-->
    <!--      :category="'discount'"-->
    <!--      :seeAllUrl="'/listing-deals'"-->
    <!--      :listingType="'R'"-->
    <!--    />-->
    <panorama />
    <listings
      :listingType="'R'"
      :useBackground="true"
      key="listings-residential"
      :category="'residential'"
      :title="$t('homepage.title.RRent')"
      :seeAllUrl="'/sewa/search/semua?property_category=7'"
    />
    <listings
      :listingType="'R'"
      key="listings-commercials"
      :category="'commercial'"
      :title="$t('homepage.title.CRent')"
      :seeAllUrl="'/sewa/search/semua?property_category=7'"
    />
    <!--    <listings-->
    <!--      key="listings-project-partners"-->
    <!--      :category="'projectpartner'"-->
    <!--      :title="$t('homepage.title.PSell')"-->
    <!--      :seeAllUrl="'/beli/search/semua?search_type=1'"-->
    <!--    />-->
    <!--    <listings-->
    <!--      :listingType="'S'"-->
    <!--      :useBackground="true"-->
    <!--      key="listings-residential-sell"-->
    <!--      :category="'residential'"-->
    <!--      :title="$t('homepage.title.RSell')"-->
    <!--      :seeAllUrl="'/beli/search/semua?property_category=7'"-->
    <!--    />-->
    <!--    <listings-->
    <!--      :listingType="'S'"-->
    <!--      key="listings-commercials-sell"-->
    <!--      :category="'commercial'"-->
    <!--      :title="$t('homepage.title.CSell')"-->
    <!--      :seeAllUrl="'/beli/search/semua?property_category=7'"-->
    <!--    />-->
    <manager></manager>
    <partner />
    <about></about>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Search from '@/components/home/search';
// import Promo from '@/components/home/promo';
import Listings from '@/components/home/listings';
import Panorama from '@/components/home/panorama';
import Manager from '@/components/home/manager';
import Partner from '@/components/home/partner';
import About from '@/components/home/about';
import { mapState } from 'vuex';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    Search,
    // Promo,
    Listings,
    Panorama,
    Manager,
    Partner,
    About,
  },
  async fetch({ store, route }) {
    let listingType = 'R';
    if (route.name === 'home-sewa') {
      listingType = 'R';
    } else if (route.name === 'home-beli') {
      listingType = 'S';
    }

    store.dispatch('v2/search/setListingType', {
      payload: listingType,
      query: route.query,
      clearRoute: true,
    });

    if (route.name === 'home') {
      store.commit('head/RESTORE_DEFAULT_VALUE', route);
    } else {
      const baseUrl = store.state.global.baseUrl;
      const title =
        (listingType === 'S' ? 'Beli' : 'Sewa') +
        ' Properti di Rentfix.com - Platform Sewa Properti di Indonesia';
      const description =
        'Nikmati kemudahan sewa properti pada platform Rentfix dengan transaksi online yang aman dan terjangkau. Rentfix membantu Anda melakukan proses sewa properti mulai dari rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di seluruh Indonesia.';
      const head = {
        title: title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
          {
            hid: 'og-description',
            name: 'og:description',
            content: description,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    }
  },
  computed: {
    ...mapState({
      listingType: (state) => state.v2.search.listingType,
    }),
    jsonldBreadcrumb() {
      let baseUrl = this.$store.state.global.baseUrl;
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
        ],
      };
    },
  },
  methods: {},
};
</script>
