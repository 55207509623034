<template>
  <section class="home--manager-container">
    <div class="container">
      <h2 class="title--text">
        {{ $t('home.managerSection.title') }}
      </h2>
      <div class="d-flex flex-wrap">
        <div class="col-12 col-md-4 manager--col">
          <img src="@/assets/img/home/space-manager.svg" alt="Space Manager - Rentfix" />
          <h3 class="title--text">
            {{ $t('home.managerSection.spaceManager') }}
          </h3>
          <div class="desc">
            {{ $t('home.managerSection.spaceSub') }}
          </div>
          <router-link to="/space-manager" class="btn">
            {{ $t('home.managerSection.spaceBtn') }}
          </router-link>
        </div>
        <div class="col-12 col-md-4 manager--col">
          <img src="@/assets/img/home/co-renting.svg" alt="Co-Renting - Rentfix" />
          <h3 class="title--text">
            {{ $t('home.managerSection.coRenting') }}
          </h3>
          <div class="desc">
            {{ $t('home.managerSection.coRentingSub') }}
          </div>
          <router-link to="co-renting" class="btn">
            {{ $t('home.managerSection.coRentingBtn') }}
          </router-link>
        </div>
        <div class="col-12 col-md-4 manager--col">
          <img src="@/assets/img/home/corporate-client.svg" alt="Corporate Client - Rentfix" />
          <h3 class="title--text">
            {{ $t('home.managerSection.corporateClient') }}
          </h3>
          <div class="desc">
            {{ $t('home.managerSection.corporateSub') }}
          </div>
          <router-link to="corporate-client" class="btn">
            {{ $t('home.managerSection.corpoBtn') }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {},
  methods: {},
};
</script>
