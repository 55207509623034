<template>
  <section class="home--search-container">
    <!--    <div class="container d-flex align-items-start flex-wrap">-->
    <!--      <div class="search&#45;&#45;container">-->
    <!--        <div class="title&#45;&#45;text">-->
    <!--          {{ $t('home.title') }}-->
    <!--        </div>-->
    <!--        <div class="switch">-->
    <!--          <div-->
    <!--            class="btn mr-2"-->
    <!--            :class="listingType === 'R' ? 'btn-primary' : 'btn-primary&#45;&#45;outline'"-->
    <!--            @click="changeType('R')"-->
    <!--          >-->
    <!--            {{ $t('general.rent') }}-->
    <!--          </div>-->
    <!--          <div-->
    <!--            class="btn"-->
    <!--            :class="listingType === 'S' ? 'btn-primary' : 'btn-primary&#45;&#45;outline'"-->
    <!--            @click="changeType('S')"-->
    <!--          >-->
    <!--            {{ $t('general.sell') }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="dropdown&#45;&#45;wrapper d-flex">-->
    <!--          <client-only>-->
    <!--            <v-select-->
    <!--              :value="activePropertyType"-->
    <!--              @input="inputPropertyTypeHandler"-->
    <!--              :items="propertyTypes"-->
    <!--              :item-text="propertyTypeLabel"-->
    <!--              item-value="id"-->
    <!--              :label="$t('general.propertyType')"-->
    <!--              class="dropdown left"-->
    <!--              solo-->
    <!--            ></v-select>-->
    <!--            <v-select-->
    <!--              v-if="listingType === 'R'"-->
    <!--              :value="activeRangePrice"-->
    <!--              @input="inputRangePriceHandler"-->
    <!--              :items="rangePrices"-->
    <!--              item-value="id"-->
    <!--              :label="$t('general.price')"-->
    <!--              class="dropdown right"-->
    <!--              solo-->
    <!--            >-->
    <!--              <template slot="selection" slot-scope="{ item }">-->
    <!--                <span class="v-select__selection mr-2">{{ $t(item.name) }}</span>-->
    <!--              </template>-->
    <!--              <template slot="item" slot-scope="{ item }">-->
    <!--                <span class="mr-2">{{ $t(item.name) }}</span>-->
    <!--                <span v-if="item.id >= 1 && item.id <= 3">({{ $t('general.filter.weekly') }})</span>-->
    <!--                <span v-else-if="item.id >= 4 && item.id <= 6"-->
    <!--                  >({{ $t('general.filter.monthly') }})</span-->
    <!--                >-->
    <!--                <span v-else-if="item.id >= 7 && item.id <= 9"-->
    <!--                  >({{ $t('general.filter.yearly') }})</span-->
    <!--                >-->
    <!--                <span v-else-if="item.id >= 10 && item.id <= 12"-->
    <!--                  >({{ $t('general.filter.hourly') }})</span-->
    <!--                >-->
    <!--                <span v-else-if="item.id >= 13 && item.id <= 15"-->
    <!--                  >({{ $t('general.filter.daily') }})</span-->
    <!--                >-->
    <!--              </template>-->
    <!--            </v-select>-->
    <!--          </client-only>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <client-only>-->
    <!--        &lt;!&ndash;        <div class="home&#45;&#45;map-wrapper">&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;        <img src="@/assets/img/home/banner-1.svg" />&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          <img&ndash;&gt;-->
    <!--        &lt;!&ndash;            v-if="$mq !== 'xs'"&ndash;&gt;-->
    <!--        &lt;!&ndash;            @click="goToSearch"&ndash;&gt;-->
    <!--        &lt;!&ndash;            src="https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/map/com+search+web+20-9-2021.png"&ndash;&gt;-->
    <!--        &lt;!&ndash;          />&ndash;&gt;-->
    <!--        &lt;!&ndash;          <img&ndash;&gt;-->
    <!--        &lt;!&ndash;            v-else&ndash;&gt;-->
    <!--        &lt;!&ndash;            @click="goToSearch"&ndash;&gt;-->
    <!--        &lt;!&ndash;            src="https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/map/com+search+mobile+20-9-2021.jpg"&ndash;&gt;-->
    <!--        &lt;!&ndash;          />&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;        <client-only>&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;          <maps&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;            v-if="listings.length > 0"&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;            :listings="listings"&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;            :meta="meta"&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;            :mapTypeControl="true"&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;            :isLoading="false"&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;          />&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;          &lt;!&ndash;        </client-only>&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        <div class="property&#45;&#45;types-wrapper">-->
    <!--          <div-->
    <!--            v-for="(content, index) in propertyTypes"-->
    <!--            :key="`banner-property-type-${index}`"-->
    <!--            :class="{ 'property&#45;&#45;type-item': listingType === 'R' || !content.onlyRent }"-->
    <!--          >-->
    <!--            <router-link-->
    <!--              :to="`/${listingType === 'R' ? 'sewa' : 'beli'}/search${content.url}`"-->
    <!--              v-if="listingType === 'R' || !content.onlyRent"-->
    <!--            >-->
    <!--              <div class="img&#45;&#45;wrapper">-->
    <!--                <img :src="content.img" :alt="content.name" />-->
    <!--              </div>-->
    <!--              <div>{{ content.name }}</div>-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </client-only>-->
    <!--    </div>-->
    <promo />
    <div class="container">
      <div class="search--bottom-part">
        <div class="search--container">
          <!--          <rent-sell-switch-->
          <!--            class="justify-center"-->
          <!--            :listingType="listingType"-->
          <!--            :isHomepage="true"-->
          <!--            @changeType="changeType"-->
          <!--          ></rent-sell-switch>-->
          <form @submit.prevent="goToSearch">
            <div class="search--listing-wrapper">
              <search-box ref="searchBox" />
              <button class="input--btn" type="submit">
                <img src="@/assets/img/icons/search.svg" alt="Cari" />
              </button>
            </div>
          </form>
          <div class="type--dropdown-group">
            <home-listing-type
              :listingType="listingType"
              :propertyTypes="propertyTypes"
              :title="$t('general.propertyTypes')"
            ></home-listing-type>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LodashMixin from '@/mixins/lodash';
// const RentSellSwitch = () => import('@/components/utils/rent-sell-switch');
const HomeListingType = () => import('@/components/home/partials/home-listing-type');
import Promo from '@/components/home/promo';

import SearchBox from '@/components/search/search-box.vue';
export default {
  components: {
    SearchBox,
    // RentSellSwitch,
    HomeListingType,
    Promo,
  },
  mixins: [LodashMixin],
  data: () => ({
    meta: null,
    listings: [],
    propertyTypes: [
      {
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/home.svg',
        name: 'Rumah',
        url: '/rumah/?property_type_id=%255B26%255D',
        onlyRent: false,
      },
      {
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/hdb.svg',
        name: 'Apartemen',
        url: '/apartemen/?property_type_id=%255B27%255D',
        onlyRent: false,
      },
      // {
      //   img:
      //     'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/shophouse.svg',
      //   name: 'Kamar',
      //   url: '/kamar',
      //   onlyRent: false,
      // },
      {
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/villa.png',
        name: 'Villa',
        url: '/villa/?property_type_id=%255B30%255D',
        onlyRent: true,
      },
      {
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/hdb-shophouse.svg',
        name: 'Ruko',
        url: '/ruko/?property_type_id=%255B1%255D',
        onlyRent: false,
      },
      {
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/warehouse.svg',
        name: 'Gudang',
        url: '/gudang/?property_type_id=%255B2%255D',
        onlyRent: false,
      },
      {
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/office-building.svg',
        name: 'Kantor',
        url: '/kantor/?property_type_id=%255B3%255D',
        onlyRent: false,
      },
      // {
      //   img:
      //     'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/food-stand.png',
      //   name: 'Kios',
      //   url: '/kios',
      //   onlyRent: true,
      // },
      // {
      //   img:
      //     'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/shop.svg',
      //   name: 'Toko',
      //   url: '/toko',
      //   onlyRent: false,
      // },
      // {
      //   img:
      //     'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/property-type/ticket-office.png',
      //   name: 'Booth',
      //   url: '/booth',
      //   onlyRent: true,
      // },
      {
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/meeting.png',
        name: 'Co-working Space',
        url: '/co-working%20space/?property_type_id=%255B8%255D',
        onlyRent: true,
      },
      // {
      //   img:
      //     'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/board-meeting.png',
      //   name: 'Ruang Pertemuan',
      //   url: '/ruang%20pertemuan',
      //   onlyRent: true,
      // },
      // {
      //   img:
      //     'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/hdb.svg',
      //   name: 'Virtual Office',
      //   url: '/virtual%20office/?property_type_id=%255B12%255D',
      //   onlyRent: true,
      // },
      // {
      //   img:
      //     'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/shophouse.svg',
      //   name: 'Event Space',
      //   url: '/event%20space',
      //   onlyRent: true,
      // },
      {
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg',
        name: 'Serviced Office',
        url: '/serviced%20office/?property_type_id=%255B14%255D',
        onlyRent: true,
      },
    ],
  }),
  computed: {
    ...mapState({
      listingType: (state) => state.v2.search.listingType,
      searchType: (state) => state.v2.search.searchType,
    }),
    ...mapGetters({
      activePropertyType: 'v2/search/activePropertyType',
      // propertyTypes: 'v2/masters/propertyTypesSearch',
      activeRangePrice: 'v2/search/activeRangePrice',
      rangePrices: 'v2/search/rangePricesSearch',
    }),
  },
  mounted() {
    this.getPropertyTypes();
    // this.initMap();
    this.$store.commit('v2/search/CLEAR_FILTER');
    this.$refs.searchBox.clearAll();
  },
  methods: {
    // async initMap() {
    //   try {
    //     this.loadingMap = true;
    //     const { data } = await this.$http.get(
    //       '/api/v2/search?listing_type=R&page=1&sort_by=1&paginate=2000',
    //     );
    //     this.listings = data.data.data;
    //     this.meta = data.data.meta;
    //     // eslint-disable-next-line no-empty
    //   } catch (_) {
    //   } finally {
    //     this.loadingMap = false;
    //   }
    // },

    propertyTypeLabel(value) {
      return value.name === 'general.all' ? this.$i18n.t(`${value.name}`) : value.name;
    },
    inputPropertyTypeHandler(value) {
      this.$store.commit('v2/search/SET_PROPERTY_TYPE_ID', value);
    },
    inputRangePriceHandler(value) {
      this.$store.commit('v2/search/SET_RANGE_PRICE', value);
    },
    // changeType(newType) {
    //   this.currentKeyword = null;
    //   this.$store.commit('v2/search/SET_SEARCH_TERM', null);
    //   this.$store.commit('v2/search/SET_SEARCH_TERM_ID', null);
    //   this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', null);
    //   this.inputPropertyTypeHandler(null);
    //   this.inputRangePriceHandler(0);
    //   this.$store.commit('v2/search/SET_LISTING_TYPE', newType);
    //   this.getPropertyTypes();
    // },
    async getPropertyTypes() {
      await this.$store.dispatch('v2/masters/getPropertyTypes', {});
    },
    goToSearch() {
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>
