<template>
  <div>
    <v-slide-group
      show-arrows
      v-if="officialPartnerAreas && officialPartnerAreas.length > 0"
      class="carousel--wrapper listing--slick"
    >
      <v-slide-item
        class="carousel--slide official--partner-slide"
        v-for="officialPartnerArea in officialPartnerAreas"
        :key="officialPartnerArea.uuid"
      >
        <card-official-partner
          :officialPartner="officialPartnerArea"
          :allowMultiImage="false"
          :key="officialPartnerArea.uuid"
        />
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import CardOfficialPartner from '@/components/utils/card-official-partner';
export default {
  components: { CardOfficialPartner },
  props: {
    officialPartnerAreas: {
      default: [],
      require: true,
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
